import routes from '@/router/routes'
import { isLegacyUrl } from './routeUtils'
import env from '@/environment'

import {
  EMPLOYEE_PATHS,
  EMPLOYER_PATHS,
  INCIDENT_REGISTRY_PATHS,
  ADMIN_TOOLS_PATHS,
  CONTROL_PANEL_PATHS,
  RISK_CENTER_PAHTS
} from '@/constants/navigation'

const routeMapping = {
  'Absence': {
    oldPath: '/ansatte/fravaer/mittFravaer.xhtml',
    newPath: EMPLOYEE_PATHS.ABSENCE
  },
  'Competence': {
    oldPath: '/kompetanseoversikt/my_competence.xhtml',
    newPath: EMPLOYEE_PATHS.COMPETENCE
  },
  'ActionPlan': {
    oldPath: '/hendelsesregister/tiltak/yearplan.xhtml',
    newPath: INCIDENT_REGISTRY_PATHS.ACTION_PLAN
  },
  'AdminAccountOverview': {
    oldPath: '/admintools/kontoadmin.xhtml',
    newPath: ADMIN_TOOLS_PATHS.OVERVIEW
  },
  'AdminAccountSupportAccess': {
    oldPath: '/admintools/supportaccess.xhtml',
    newPath: ADMIN_TOOLS_PATHS.SUPPORT_ACCESS
  },
  'AdminAccountSupportUsers': {
    oldPath: '/admintools/supportusers.xhtml',
    newPath: ADMIN_TOOLS_PATHS.SUPPORT_USERS
  },
  'Inspections': {
    oldPath: '/hendelsesregister/tilsynsrapport/tilsynsrapporter.xhtml',
    newPath: INCIDENT_REGISTRY_PATHS.INSPECTIONS
  },
  'MyHours': {
    oldPath: '/timeregistrering/minesider_timefoering.xhtml',
    newPath: EMPLOYEE_PATHS.HOURS
  },
  'MyVacation': {
    oldPath: '/ansatte/ferie/minferie.xhtml',
    newPath: EMPLOYEE_PATHS.VACATION
  },
  'MyIncidents': {
    oldPath: '/hendelsesregister/hendelse/mineHendelser.xhtml',
    newPath: EMPLOYEE_PATHS.INCIDENTS
  },
  'SafetyInspections': {
    oldPath: '/hendelsesregister/vernerunde/vernerunder.xhtml',
    newPath: INCIDENT_REGISTRY_PATHS.SAFETY_INSPECTIONS
  },
  'RiskAssessments': {
    oldPath: '/risikosenter/ros/riskAssessmentsOverview.xhtml',
    newPath: RISK_CENTER_PAHTS.RISK_ASSESSMENT
  },
  'CustomerSupport': {
    oldPath: '/kontrollpanel/supportAccounts.xhtml',
    newPath: CONTROL_PANEL_PATHS.CUSTOMER_SUPPORT
  },
  'EmployerCompetence': {
    oldPath: '/kompetanseoversikt/kompetanseoversikt.xhtml',
    newPath: EMPLOYER_PATHS.COMPETENCE
  },
  'EmployerAbsence': {
    oldPath: '/ansatte/fravaer/fravaer.xhtml',
    newPath: EMPLOYER_PATHS.ABSENCE
  },
  'EmployerVacation': {
    oldPath: '/ansatte/ferie/ferie.xhtml',
    newPath: EMPLOYER_PATHS.VACATION
  }
}

export const getRouteHrefByName = routeName => {
  // Filter out iFrames and get routes available in production
  const allRoutes = Object.values(routes)
    .flatMap(obj => Object.values(obj))
    .filter(x => x.name !== 'iFrameMonolith')

  // Check if the route exists in the mapping and handle accordingly
  const routeInfo = routeMapping[routeName]
  if (!routeInfo) {
    throw new Error(`Route "${routeName}" not found in route mapping.`)
  }

  if (env.VUE_APP_ENVIRONMENT === 'local') return routeInfo.newPath
  return allRoutes.some(
    x =>
      (x.name === routeName && x.productionAvailable) ||
      (x.name === routeName && x.productionAvailable == null)
  )
    ? routeInfo.newPath
    : routeInfo.oldPath
}

export const isLegacy = url => isLegacyUrl(url)
