import { reactive, toRefs } from 'vue'

import { useLogger } from '@/shared/logger'
import { removeItemAtIndex } from '@/utilities/arrayUtilities'
import { addNewItem } from '@/utilities/table'

import { httpMethods, requestHandlerWithState } from './requestHandler'

const state = reactive({
  incidentsList: [],
  incidentSchemaTypes: [],
  incident: {},
  incidentLocations: [],
  incidentCategories: [],
  incidentTypes: [],
  allEmployeesAndContacts: [],
  incidentSettings: [],
  incidentTypeSettings: [],
  request: {
    loaded: false,
    loading: false,
    saving: false,
    errors: []
  }
})

const logger = useLogger('incident service')

export const useIncidentService = () => {
  const getMyIncidentList = async () => {
    const url = '/hse/api/incidents/myincidents'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    state.incidentsList = result.data
  }

  const getIncidentList = async () => {
    const url = '/hse/api/incidents'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    state.incidentsList = result.data
  }

  const getMyIncidentById = async id => {
    const url = `/hse/api/incidents/myincident/${id}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    state.incident = result.data
  }

  const getIncidentById = async id => {
    const url = `/hse/api/incidents/${id}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    state.incident = result.data
    return result.data
  }

  const getIncidentSchemaTypes = async () => {
    const url = 'hse/api/incidents/getselectabletypes'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    state.incidentSchemaTypes = result.data
  }

  const getIncidentLocations = async () => {
    const url = 'hse/api/incidents/getincidentlocations'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    state.incidentLocations = result.data
  }

  const getIncidentCategories = async () => {
    const url = 'hse/api/incidents/getincidentcategories'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    state.incidentCategories = result.data
  }

  const getIncidentTypes = async type => {
    const url = `/hse/api/incidents/gettypesfortype/${type}`
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    state.incidentTypes = result.data
  }

  const getIncidentTypeSettings = async () => {
    const url = '/common/api/settings/incidenttypesettings'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    state.incidentTypeSettings = result.data
  }

  const getIncidentSettings = async () => {
    const url = '/common/api/settings/incidentsetting'
    const result = await requestHandlerWithState(state, httpMethods.GET, url)
    state.incidentSettings = result.data
  }

  const addOrUpdateIncidentList = result => {
    if (!state.incidentsList.some(incident => incident.id === result.id)) {
      addNewItem({ ...result }, state.incidentsList)
    } else {
      state.incidentsList = state.incidentsList.map(incident =>
        incident.id === result.id ? result : incident
      )
    }
  }

  const handleIncidentAPICalls = async (dto, url) => {
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      dto
    )

    if (result.success) {
      result.data.incidentDto = {
        ...result.data.incidentDto,
        closed_date: null
      }
      state.incident = result.data.incidentDto
      addOrUpdateIncidentList(result.data.incidentDto)
    }

    return result
  }

  const saveIncident = async IncidentDto => {
    const url = 'hse/api/incidents/saveincident'
    return handleIncidentAPICalls(IncidentDto, url)
  }

  const saveMyIncident = async IncidentDto => {
    const url = 'hse/api/incidents/savemyincident'
    return handleIncidentAPICalls(IncidentDto, url)
  }

  const deleteIncident = async id => {
    const url = `hse/api/incidents/deleteincident/${id}`
    const result = await requestHandlerWithState(state, httpMethods.DELETE, url)

    if (result.success) {
      removeItemAtIndex(state.incidentsList, id)
    }

    return result
  }

  const addCategory = async category => {
    const url = 'hse/api/incidents/addcategory'
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      category
    )

    if (result.success) addNewItem({ ...result.data }, state.incidentCategories)
    return result
  }

  const addLocation = async location => {
    const url = 'hse/api/incidents/addlocation'
    const result = await requestHandlerWithState(
      state,
      httpMethods.POST,
      url,
      location
    )

    if (result.success) addNewItem({ ...result.data }, state.incidentLocations)
    return result
  }

  return {
    state: toRefs(state),
    getMyIncidentList,
    getIncidentList,
    getIncidentById,
    getMyIncidentById,
    getIncidentSchemaTypes,
    getIncidentLocations,
    getIncidentCategories,
    getIncidentTypes,
    getIncidentSettings,
    getIncidentTypeSettings,
    saveIncident,
    saveMyIncident,
    deleteIncident,
    addCategory,
    addLocation
  }
}
