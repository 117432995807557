import axios from 'axios'
import i18n from './i18n'
import env from '@/environment'
import { getToken } from '@/services/authentication/authenticationUtilities'

const avonovaAPI = axios.create()

avonovaAPI.interceptors.request.use(
  async config => {
    config.baseURL = env.VUE_APP_API_URL

    // Handle async token retrieval
    const tokenPromise = getToken() // This is now a promise
    return tokenPromise.then(token => {
      config.headers.common['Authorization'] = token

      // Add accept language
      const language = i18n.locale
      config.headers['ACCEPT-LANGUAGE'] = language

      return config // Return config after token is set
    })
  },
  error => {
    return Promise.reject(error)
  }
)

avonovaAPI.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export default avonovaAPI
