export const POLLING_TIME_IN_MINUTE = 60000 // 1 minute

export const FLAG = {
  USE_NEW_FILE_SERVICE: 'use_new_file_service',
  INTERNAL_QA_ACCESS_ENABLED: 'internal_qa_access_enabled',
  USE_NEW_SAFETY_INSCPETION: 'useNewSafetyInspection',
  USE_IFRAME_MONOLITH: 'use_iframe_monolith',
  USE_DOWNLOAD_FILE_HACK: 'useDownloadFileHack',
  SHOW_TIME_RECORDER: 'show-timerecoder',
  USE_CUSTOMER_PRODUCT_PACKAGE_SERVICE: 'use-customer-product-package'
}
