import { ACCESS_RIGHTS } from '@/constants/menuAccess.js'
import { useEmployerAbsenceBreadcrumbs } from './employerBreadcrumbs'
import { EMPLOYER_PATHS } from '@/constants/navigation.js'

const { absenceBreadcrumbs, vacationBreadcrumbs } =
  useEmployerAbsenceBreadcrumbs()

const employerRoutes = [
  {
    path: EMPLOYER_PATHS.COMPETENCE,
    alias: [
      '/employer/competence/new',
      '/employer/competence/filter',
      '/employer/competence/edit'
    ],
    name: 'EmployerCompetence',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "EmployerCompetenceHero" */
          `@/views/employer/competence/components/EmployerCompetenceHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "EmployerCompetence" */
          `@/views/employer/competence/EmployerCompetence.vue`
        )
    },
    meta: {
      title: 'meta__title__employer_competence',
      menuAccess: ACCESS_RIGHTS.EMPLOYER_COMPETENCE,
      contentful: {
        category: '2eT39JXo7AHBLQgLfsoc62',
        pageHelp: '1JSOg4iWYqXtNXiuzPcyId'
      },
      breadcrumbs: [
        {
          text: 'Competence',
          string: 'meta__title__employer_competence',
          disabled: false
        }
      ]
    },
    productionAvailable: true
  },
  {
    path: EMPLOYER_PATHS.VACATION,
    alias: ['/employer/vacation/filter'],
    name: 'EmployerVacation',
    components: {
      default: () => import('@/views/employer/vacation/EmployerVacation.vue')
    },
    meta: {
      title: 'meta__title_vacation',
      contentful: {
        category: '7nmECkK4a68bl15uuvdRsl',
        pageHelp: '6Ax9NdY60PafQwOTUX2Azd'
      },
      breadcrumbs: vacationBreadcrumbs.parent
    },
    children: [
      {
        path: '',
        redirect: 'overview'
      },
      {
        path: 'overview',
        name: 'EmployerVacationOverview',
        alias: ['overview/new'],
        components: {
          hero: () =>
            import(
              '@/views/employer/vacation/components/EmployerVacationHero.vue'
            ),
          default: () =>
            import(
              '@/views/employer/vacation/components/vacation-overview/EmployerVacationOverview.vue'
            )
        },
        meta: {
          title: 'meta__title_vacation',
          menuAccess: ACCESS_RIGHTS.EMPLOYER_VACATION,
          contentful: {
            category: '7nmECkK4a68bl15uuvdRsl',
            pageHelp: '6Ax9NdY60PafQwOTUX2Azd'
          },
          breadcrumbs: [
            ...vacationBreadcrumbs.parent,
            vacationBreadcrumbs.overview
          ]
        }
      },
      {
        path: 'calendar',
        name: 'EmployerVacationCalendar',
        components: {
          hero: () =>
            import(
              '@/views/employer/vacation/components/EmployerVacationHero.vue'
            ),
          default: () =>
            import(
              '@/views/employer/vacation/components/vacation-calendar/EmployerVacationCalendar.vue'
            )
        },
        meta: {
          title: 'meta__title_vacation',
          menuAccess: ACCESS_RIGHTS.EMPLOYER_VACATION,
          contentful: {
            category: '7nmECkK4a68bl15uuvdRsl',
            pageHelp: '6Ax9NdY60PafQwOTUX2Azd'
          },
          breadcrumbs: [
            ...vacationBreadcrumbs.parent,
            vacationBreadcrumbs.calender
          ]
        }
      },
      {
        path: 'transfer',
        name: 'EmployerVacationTransfer',
        components: {
          hero: () =>
            import(
              '@/views/employer/vacation/components/EmployerVacationHero.vue'
            ),
          default: () =>
            import(
              '@/views/employer/vacation/components/vacation-transfer/EmployerVacationTransfer.vue'
            )
        },
        meta: {
          title: 'meta__title_vacation',
          menuAccess: ACCESS_RIGHTS.EMPLOYER_VACATION,
          contentful: {
            category: '7nmECkK4a68bl15uuvdRsl',
            pageHelp: '6Ax9NdY60PafQwOTUX2Azd'
          },
          breadcrumbs: [
            ...vacationBreadcrumbs.parent,
            vacationBreadcrumbs.transfer
          ]
        }
      }
    ],
    productionAvailable: true
  },
  {
    path: EMPLOYER_PATHS.EMPLOYEE_OVERVIEW,
    name: 'EmployerEmployeeOverview',
    components: {
      hero: () =>
        import(
          `@/views/employer/employees-overview/components/EmployeeOverviewHero.vue`
        ),
      default: () =>
        import(`@/views/employer/employees-overview/EmployeeOverview.vue`)
    },
    meta: {
      title: 'meta__title__employer_employee_overview',
      contentful: {
        category: '4vNtpW8xJLZAUyVPFFB7qD',
        pageHelp: '1JSOg4iWYqXtNXiuzPcyId'
      },
      breadcrumbs: [
        {
          text: 'Employee overview',
          string: 'meta__title__employer_employee_overview',
          disabled: false,
          href: '/employer/employee-overview'
        }
      ]
    },
    children: [
      {
        path: 'employees',
        name: 'EmployerEmployeeOverviewEmployees',
        components: {
          hero: () =>
            import(
              `@/views/employer/employees-overview/components/EmployeeOverviewHero.vue`
            ),
          default: () =>
            import(
              `@/views/employer/employees-overview/components/employees/EmployeesTab.vue`
            )
        },
        meta: {
          title: 'meta__title__employer_employee_overview',
          menuAccess: ACCESS_RIGHTS.EMPLOYER_EMPLOYEES
        }
      },
      {
        path: 'deleted-employees',
        name: 'EmployerEmployeeOverviewDeletedEmployees',
        components: {
          hero: () =>
            import(
              `@/views/employer/employees-overview/components/EmployeeOverviewHero.vue`
            ),
          default: () =>
            import(
              `@/views/employer/employees-overview/components/deleted-employees/DeletedEmployeesTab.vue`
            )
        },
        meta: {
          title: 'meta__title__employer_employee_overview',
          menuAccess: ACCESS_RIGHTS.EMPLOYER_EMPLOYEES
        }
      }
    ],
    productionAvailable: false
  },
  {
    path: EMPLOYER_PATHS.ABSENCE,
    alias: ['/employer/absence'],
    name: 'EmployerAbsence',
    components: {
      default: () => import(`@/views/employer/absence/EmployerAbsence.vue`)
    },
    meta: {
      menuAccess: ACCESS_RIGHTS.EMPLOYER_ABSENCE,
      title: 'meta__title__employer_absence',
      contentful: {
        category: '2fkFvy59YoesRpxnlzJXe6',
        pageHelp: '6xJbu010Wk7wl7DuhqHlHX'
      },
      breadcrumbs: absenceBreadcrumbs.parent
    },
    productionAvailable: true,
    children: [
      {
        path: '',
        redirect: 'overview'
      },
      {
        path: 'overview',
        name: 'EmployerAbsenceOverview',
        alias: ['overview/new'],
        components: {
          hero: () =>
            import(
              `@/views/employer/absence/components/EmployerAbsenceHero.vue`
            ),
          default: () =>
            import(
              `@/views/employer/absence/components/employees-overview/EmployeesOverview.vue`
            )
        },
        meta: {
          title: 'meta__title__employer_absence',
          contentful: {
            category: '2fkFvy59YoesRpxnlzJXe6',
            pageHelp: '6xJbu010Wk7wl7DuhqHlHX'
          },
          breadcrumbs: [
            ...absenceBreadcrumbs.parent,
            absenceBreadcrumbs.overview
          ]
        }
      },
      {
        path: 'calendar',
        name: 'EmployerAbsenceCalendar',
        components: {
          hero: () =>
            import(
              `@/views/employer/absence/components/EmployerAbsenceHero.vue`
            ),
          default: () =>
            import(
              `@/views/employer/absence/components/absence-calendar/AbsenceCalendar.vue`
            )
        },
        meta: {
          title: 'meta__title__employer_absence',
          contentful: {
            category: '2fkFvy59YoesRpxnlzJXe6',
            pageHelp: '6xJbu010Wk7wl7DuhqHlHX'
          },
          breadcrumbs: [
            ...absenceBreadcrumbs.parent,
            absenceBreadcrumbs.calendar
          ]
        }
      },
      {
        path: 'follow-up',
        alias: ['followup'],
        name: 'EmployerAbsenceFollowUp',
        components: {
          hero: () =>
            import(
              `@/views/employer/absence/components/EmployerAbsenceHero.vue`
            ),
          default: () =>
            import(
              `@/views/employer/absence/components/absence-follow-up/AbsenceFollowUp.vue`
            )
        },
        meta: {
          title: 'meta__title__employer_absence',
          menuAccess: ACCESS_RIGHTS.EMPLOYER_FOLLOWUP_ABSENCE,
          contentful: {
            category: '2fkFvy59YoesRpxnlzJXe6',
            pageHelp: '6xJbu010Wk7wl7DuhqHlHX'
          },
          breadcrumbs: [
            ...absenceBreadcrumbs.parent,
            absenceBreadcrumbs.followup
          ]
        }
      },
      {
        path: 'report',
        name: 'EmployerAbsenceReport',
        components: {
          hero: () =>
            import(
              `@/views/employer/absence/components/EmployerAbsenceHero.vue`
            ),
          default: () =>
            import(
              `@/views/employer/absence/components/absence-report/AbsenceReport.vue`
            )
        },
        meta: {
          title: 'meta__title__employer_absence',
          contentful: {
            category: '2fkFvy59YoesRpxnlzJXe6',
            pageHelp: '6xJbu010Wk7wl7DuhqHlHX'
          },
          breadcrumbs: [...absenceBreadcrumbs.parent, absenceBreadcrumbs.report]
        },
        contentful: {
          category: '2fkFvy59YoesRpxnlzJXe6',
          pageHelp: '6xJbu010Wk7wl7DuhqHlHX'
        }
      }
    ]
  },
  {
    path: `${EMPLOYER_PATHS.ABSENCE}/follow-up/:id`,
    alias: ['/employer/absence/followup/:id'],
    name: 'EmployerAbsenceFollowUpWithId',
    components: {
      default: () =>
        import(
          `@/views/employer/absence/components/absence-follow-up/FollowUpOverview.vue`
        )
    },
    children: [
      { path: '', redirect: 'followup-form' },
      {
        path: 'followup-form',
        name: 'FollowUpForm',
        components: {
          hero: () =>
            import(
              `@/views/employer/absence/components/absence-follow-up/FollowUpHero.vue`
            ),
          default: () =>
            import(
              '@/views/employer/absence/components/absence-follow-up/FollowUpForm.vue'
            )
        },
        meta: {
          title: 'meta__title__employer_absence_follow_up',
          menuAccess: ACCESS_RIGHTS.EMPLOYER_FOLLOWUP_ABSENCE,
          contentful: {
            category: '2fkFvy59YoesRpxnlzJXe6',
            pageHelp: '5rgsSvgtPcZxLN7q6x69Zc'
          },
          breadcrumbs: [
            ...absenceBreadcrumbs.parent,
            absenceBreadcrumbs.followup
          ]
        }
      },
      {
        path: 'events',
        name: 'CourseOfEvents',
        components: {
          hero: () =>
            import(
              `@/views/employer/absence/components/absence-follow-up/FollowUpHero.vue`
            ),
          default: () =>
            import(
              '@/views/employer/absence/components/absence-follow-up/CourseOfEvents.vue'
            )
        },
        meta: {
          title: 'meta__title__employer_absence_follow_up',
          menuAccess: ACCESS_RIGHTS.EMPLOYER_FOLLOWUP_ABSENCE,
          contentful: {
            category: '2fkFvy59YoesRpxnlzJXe6',
            pageHelp: '5rgsSvgtPcZxLN7q6x69Zc'
          }
        }
      }
    ],
    meta: {
      title: 'meta__title__employer_absence_follow_up',
      menuAccess: ACCESS_RIGHTS.EMPLOYER_FOLLOWUP_ABSENCE,
      contentful: {
        category: '2fkFvy59YoesRpxnlzJXe6',
        pageHelp: '5rgsSvgtPcZxLN7q6x69Zc'
      },
      breadcrumbs: [...absenceBreadcrumbs.parent, absenceBreadcrumbs.followup]
    },
    productionAvailable: true
  }
]

export default employerRoutes
