import { useAnnualReportService } from '../../services/annual-report/annual-report-service'
import { useLogger } from '../../shared/logger'
import { CONTROL_PANEL_PATHS } from '@/constants/navigation'

const annualReportGuard = async (to, from, next) => {
  const logger = useLogger('annualReportGuard')
  const { getReportByIdAsync } = useAnnualReportService()

  if (to.params.reportId) {
    logger.debug(`Fetching report with id: ${to.params.reportId}`)
    await getReportByIdAsync(to.params.reportId)
    logger.debug(`Report fetched. ReportId: ${to.params.reportId}`)
  }

  next()
}

const controlPanelRoutes = [
  {
    path: CONTROL_PANEL_PATHS.AVONOVA_ACCESS,
    name: 'AvonovaAccess',
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "HelpAdminHero" */ `@/views/control-panel/help-admin/HelpAdminHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "HelpAdmin" */ `@/views/control-panel/help-admin/HelpAdmin.vue`
        )
    },
    meta: {
      title: 'meta__title__account_admin',
      contentful: {
        category: '6ThPrUfIZjrYRR3C01AXiS',
        pageHelp: '3S1lnsfdZIVV0emoYxGu5h'
      },
      breadcrumbs: [
        {
          string: 'help_admin__breadcrumbs_title',
          disabled: false,
          href: '/control-panel/avonova-access'
        }
      ]
    },
    productionAvailable: true
  },
  {
    name: 'CustomerSupport',
    path: CONTROL_PANEL_PATHS.CUSTOMER_SUPPORT,
    components: {
      hero: () =>
        import(
          /* webpackChunkName: "ProjectsHero" */ `@/views/control-panel/customer-support/CustomerSupportHero.vue`
        ),
      default: () =>
        import(
          /* webpackChunkName: "Projects" */ `@/views/control-panel/customer-support/CustomerSupport.vue`
        )
    },
    meta: {
      title: 'meta__title__customer_support',
      contentful: {
        category: 'PTRYWviqqOePp2mXVa86B',
        pageHelp: 'R7oV0MFgjt6o7oew65ua9'
      },
      breadcrumbs: [
        {
          text: 'Customer Support',
          string: 'customer_support__breadcrumbs_title',
          disabled: true,
          href: '/control-panel/customer-support'
        }
      ]
    },
    productionAvailable: true
  },
  {
    name: 'AnnualReport',
    path: CONTROL_PANEL_PATHS.ANNUAL_REPORT,
    beforeEnter: annualReportGuard,
    components: {
      hero: () =>
        import(`@/views/control-panel/annual-report/AnnualReportHero.vue`),
      default: () =>
        import(`@/views/control-panel/annual-report/AnnualReport.vue`)
    },
    meta: {
      title: 'meta__title__annual_report',
      contentful: {
        category: '37qPDTTxGN8q8uUtxzZByk',
        pageHelp: ''
      },
      breadcrumbs: [
        {
          text: 'Annual Report',
          string: 'annual_report__breadcrumbs_title',
          disabled: true,
          href: '/control-panel/annual-report'
        }
      ]
    },
    productionAvailable: true,
    children: [
      {
        path: '',
        name: 'AnnualReportStart',
        component: () =>
          import(
            /* webpackChunkName: "TestComponent" */ `@/views/control-panel/annual-report/components/landingpage/LandingPage.vue`
          )
      },
      {
        path: '1/:reportId?',
        name: 'AnnualReportStep1',

        component: () =>
          import(
            /* webpackChunkName: "TestComponent" */ `@/views/control-panel/annual-report/components/frontpage/FrontPageInput.vue`
          ),
        meta: {
          reportStep: 1
        }
      },
      {
        path: '2/:reportId?',
        name: 'AnnualReportStep2',
        component: () =>
          import(
            /* webpackChunkName: "TestComponent" */ `@/views/control-panel/annual-report/components/activities/ActivitiesIntroductionPage.vue`
          ),
        meta: {
          reportStep: 2
        }
      },
      {
        path: '3/:reportId?',
        name: 'AnnualReportStep3',
        component: () =>
          import(
            /* webpackChunkName: "TestComponent" */ `@/views/control-panel/annual-report/components/activities/ActivitiesTablePage.vue`
          ),
        meta: {
          reportStep: 3
        }
      },
      {
        path: '4/:reportId?',
        name: 'AnnualReportStep4',
        component: () =>
          import(
            /* webpackChunkName: "TestComponent" */ `@/views/control-panel/annual-report/components/activities/RecommendedActivitiesPage.vue`
          ),
        meta: {
          reportStep: 4
        }
      },
      {
        path: '5/:reportId?',
        name: 'AnnualReportStep5',
        component: () =>
          import(
            /* webpackChunkName: "TestComponent" */ `@/views/control-panel/annual-report/components/conclusion/ConclusionPage.vue`
          ),
        meta: {
          reportStep: 5
        }
      },
      {
        path: '6/:reportId?',
        name: 'AnnualRepo1rtStep6',
        component: () =>
          import(
            /* webpackChunkName: "TestComponent" */ `@/views/control-panel/annual-report/components/closingpage/ClosingPage.vue`
          ),
        meta: {
          reportStep: 6
        }
      }
    ]
  }
]

export default controlPanelRoutes
